import { TwicImg } from '@twicpics/components/react';
import Image from 'next/image';
import { Box } from '@/components/common';
import { assets, externalLinks } from '@/config/assets-links';
import { detectMobileOs } from '@/utils/app-store-redirection';

export function AppQRCode({ className }: Partial<HTMLDivElement>) {
  return (
    <Box
      component="div"
      display="flex"
      gap={2}
      alignItems="center"
      className={className}
    >
      <Box width={125} display="flex">
        <Image
          src="/images/AppStoreQrCode.svg"
          width={125}
          height={125}
          alt="App QR code"
          priority
          unoptimized
        />
      </Box>
    </Box>
  );
}

export function AppDownloadLinks() {
  const mobileOs = detectMobileOs(navigator.userAgent);

  if (mobileOs === 'android') {
    return <PlayStoreBadge width={180} />;
  }

  if (mobileOs === 'iphone') {
    return <AppleStoreBadge width={180} />;
  }

  return (
    <>
      <PlayStoreBadge width={130} />
      <AppleStoreBadge width={130} />
    </>
  );
}

type BadgeProps = {
  width: number;
};

function PlayStoreBadge({ width }: BadgeProps) {
  return (
    <Box width={width}>
      <a
        href={externalLinks.propershotPlayStoreUrl}
        target="_blank"
        rel="noreferrer"
      >
        <TwicImg
          src={assets.cdnImageAssets.androidBadge}
          alt="Get it on Google Play"
          mode="cover"
          ratio={109 / 32}
        />
      </a>
    </Box>
  );
}

function AppleStoreBadge({ width }: BadgeProps) {
  return (
    <Box width={width}>
      <a
        href={externalLinks.properShotAppStoreUrl}
        target="_blank"
        rel="noreferrer"
      >
        <TwicImg
          src={assets.cdnImageAssets.iosBadge}
          alt="Download on the App Store"
          mode="cover"
          ratio={109 / 32}
        />
      </a>
    </Box>
  );
}
