import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import {
  getLocalTimeZone,
  parseAbsoluteToLocal,
  toCalendarDate,
  today,
} from '@internationalized/date';
import Head from 'next/head';
import { TwicVideo } from '@twicpics/components/react';
import { useCurrentMsUser } from '@/api/meero-suite/users';
import { useLocalStorage } from '@/hooks/localStorage/useLocalStorage';
import {
  AccessTimeIcon,
  AddIcon,
  BedOutlinedIcon,
  Box,
  CloudOutlinedIcon,
  CreateAlbumIcon,
  ImagesearchRollerOutlinedIcon,
  TurnedInNotIcon,
  Typography,
} from '@/components/common';
import { Button, ButtonWithIcon } from '@/components/common/Button/Button';
import { EmptyMediaList } from '@/components/common/EmptyListComponent/EmptyMediaList';
import { AlbumGridCard } from '@/components/common/AlbumGridCard/AlbumGridCard';
import { StyledCardActionArea } from '@/components/common/CardMediaSelect/CardMediaSelect.styles';
import { colors } from '@/theme';
import { assets, externalLinks } from '@/config/assets-links';
import { AlbumCollectionMember } from '@/types/Album/AlbumCollection';
import { trackButtonClicked } from '@/lib/analytics';
import { useUserWorkspaceContext } from '@/context/UserWorkspaceContext';
import { useDialog } from '@/context/DialogContext';
import MagicEraserIcon from '@/components/icons/MagicEraserIcon';
import { GuidelinesDialog } from '../../common/GuidelinesDialog/GuidelineDialog';
import { ONBOARDING_GUIDELINES } from '../../common/GuidelinesDialog/guidelines-config';
import {
  AddIconContainer,
  CreatePropertyCardContainer,
  EmptyStateContainer,
  FeatureDemoCard,
  FeatureDemoContainer,
  FeatureIconContainer,
  HiddenContent,
  HomeGridLayout,
  HomepageContainer,
  PageTitle,
  StyledBadge,
} from './Home.styles';
import { useImportPhotosHandlers } from '../Library/ImportPhotosDialog/useImportPhotosHandlers';
import { useLibraryAlbums } from '../Library/hooks/useGetLibraryAlbumData';
import { ImportPhotosDialog } from '../Library/ImportPhotosDialog/ImportPhotosDialog';
import { AppDownloadInstructions } from './AppDownloadInstructions/AppDownloadInstructions';
import { HomeSkeleton } from './HomeSkeletons';

const isAlbumRecent = (albumSelected: AlbumCollectionMember): boolean => {
  const albumDateCreationTime = parseAbsoluteToLocal(albumSelected.createdAt);
  const albumDateCreation = toCalendarDate(albumDateCreationTime);
  const nowDate = today(getLocalTimeZone());

  return nowDate.compare(albumDateCreation) < 5;
};

const RECENTS_ALBUM_TO_DISPLAY = 4;

export const Home = () => {
  const { t } = useTranslation('home');
  const { msUser } = useCurrentMsUser();
  const { push } = useRouter();
  const { isCreatePropertyDisabled } = useUserWorkspaceContext();

  const { handleOpenImportDialog, openImportDialog, handleCloseImportDialog } =
    useImportPhotosHandlers({ trackingOrigin: 'Home' });
  const { isEmptyAlbum, albums, deleteAlbumAndRevalidateList, isLoading } =
    useLibraryAlbums(1);

  const [homepageFirstVisit, setHomepageFirstVisit] = useLocalStorage(
    `homepage-first-visit-${msUser?.email ?? ''}`,
    true
  );

  const showGuidelines = homepageFirstVisit && isEmptyAlbum;
  const recentAlbums = albums.slice(0, RECENTS_ALBUM_TO_DISPLAY);

  if (isLoading)
    return (
      <HomeSkeleton
        isloading={isLoading}
        albumCoverToDisplay={RECENTS_ALBUM_TO_DISPLAY}
      />
    );

  return (
    <>
      <Head>
        <title>{t('tabTitle')}</title>
      </Head>
      <HomepageContainer>
        <GuidelinesDialog
          isOpen={showGuidelines}
          guidelinesConfig={ONBOARDING_GUIDELINES}
          confirmButtonVariant="letsStart"
          onConfirm={() => setHomepageFirstVisit(false)}
        />
        <ImportPhotosDialog
          key={openImportDialog ? 'open' : 'closed'}
          isOpen={openImportDialog}
          onClose={handleCloseImportDialog}
        />
        {isEmptyAlbum ? (
          <EmptyStateContainer>
            <EmptyMediaList
              listType="home"
              contentType="album"
              slot={() => handleOpenImportDialog('new-property')}
            />
            <ButtonWithIcon
              $variant="primary"
              $size="large"
              icon={<CreateAlbumIcon fontSize="medium" />}
              label={t('header.cta.createProperty')}
              style={{ fontWeight: 700 }}
              onPress={() => {
                handleOpenImportDialog('new-property');
                trackButtonClicked('Home Empty State CTA Clicked', {
                  page: 'Home',
                });
              }}
            />
          </EmptyStateContainer>
        ) : (
          <>
            <Box display="flex" gap={1}>
              <PageTitle variant="h4">
                {t('header.title', { userName: msUser?.firstname })}
              </PageTitle>
            </Box>

            <Box display="flex" flexDirection="column" rowGap={3}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                data-element="header"
              >
                <Box component="div" display="flex" alignItems="center" gap={1}>
                  <AccessTimeIcon sx={{ color: colors.miracle }} />
                  <Typography variant="h6" color="textSecondary">
                    {t('header.recentProperty')}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="end" gap={1}>
                  <Button
                    $variant="secondary"
                    $size="medium"
                    onPress={() => void push('/library')}
                  >
                    <Typography
                      fontSize="inherit"
                      fontWeight={700}
                      lineHeight="inherit"
                    >
                      {t('header.cta.viewAll')}
                    </Typography>
                  </Button>
                  {!isCreatePropertyDisabled && (
                    <ButtonWithIcon
                      $variant="primary"
                      $size="medium"
                      icon={<CreateAlbumIcon fontSize="medium" />}
                      label={t('header.cta.createProperty')}
                      style={{ fontWeight: 700 }}
                      onPress={() => handleOpenImportDialog('new-property')}
                    />
                  )}
                </Box>
              </Box>
              <HomeGridLayout>
                {recentAlbums.map((album, index) => {
                  return (
                    <AlbumGridCard
                      key={`${album.uuid}-${index}`}
                      cardType="home"
                      isAlbumNew={index === 0 && isAlbumRecent(album)}
                      albumName={album.name}
                      albumSize={album.totalIngestedMedias}
                      albumDate={album.createdAt}
                      coverS3Key={album.coverCdnS3Key}
                      albumUuid={album.uuid}
                      isFullyDelivered={album.isFullyDelivered}
                      href={`/library/albums/${album.uuid}`}
                      onDelete={() => deleteAlbumAndRevalidateList(album.uuid)}
                    />
                  );
                })}
                <CreatePropertyCardContainer>
                  <StyledCardActionArea
                    component="div"
                    $albumShadow
                    onClick={() =>
                      !isCreatePropertyDisabled
                        ? handleOpenImportDialog('new-property')
                        : null
                    }
                  >
                    <AddIconContainer>
                      <AddIcon fontSize="large" color="action" />
                    </AddIconContainer>
                  </StyledCardActionArea>
                  <HiddenContent />
                  <HiddenContent />
                </CreatePropertyCardContainer>
              </HomeGridLayout>
            </Box>
          </>
        )}
        <FeatureDemo />
        <AppDownloadInstructions />
      </HomepageContainer>
    </>
  );
};

const FeatureDemo = () => {
  const { t } = useTranslation('home');
  const { openDialog, dismissDialog } = useDialog();

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box display="flex" alignItems="-" gap={1}>
        <TurnedInNotIcon fontSize="small" color="action" />
        <Typography variant="h6" color="textSecondary">
          {t('featureDemo.title')}
        </Typography>
      </Box>
      <FeatureDemoContainer>
        <FeatureDemoCard
          target="_blank"
          onClick={() => {
            openDialog({
              variant: 'demo',
              title: t('demoDialog.magicEraser.title'),
              message: t('demoDialog.magicEraser.description'),
              ctaButtonLabel: t('demoDialog.magicEraser.ctaLabel'),
              confirmButtonLabel: t('demoDialog.magicEraser.ctaLabel'),
              onConfirm: () => {
                window.open(externalLinks.magicEraseDemoUrl, '_blank');
                trackButtonClicked('Magic Eraser Demo Clicked', {
                  page: 'Home',
                });
              },
              onClose: () => dismissDialog(),
              headerImgSlot: (
                <TwicVideo
                  src={assets.cdnVideoAssets.magicEraserDialogHeaderVideo}
                  ratio="1.9"
                  alt="Magic Eraser demo video"
                />
              ),
            });
          }}
        >
          <FeatureIconContainer>
            <MagicEraserIcon fontSize="large" />
          </FeatureIconContainer>
          <Typography fontWeight={700}>
            {t('featureDemo.magicEraser')}
          </Typography>
        </FeatureDemoCard>
        <FeatureDemoCard
          target="_blank"
          onClick={() => {
            openDialog({
              variant: 'demo',
              title: t('demoDialog.homeStaging.title'),
              message: t('demoDialog.homeStaging.description'),
              ctaButtonLabel: t('demoDialog.homeStaging.ctaLabel'),
              onConfirm: () => {
                window.open(externalLinks.homeStagingDemoUrl, '_blank');
                trackButtonClicked('Home Staging Demo Clicked', {
                  page: 'Home',
                });
              },
              onClose: () => dismissDialog(),
              headerImgSlot: (
                <TwicVideo
                  src={assets.cdnVideoAssets.homeStagingDialogHeaderVideo}
                  ratio="1.9"
                  alt="Home staging demo video"
                />
              ),
            });
          }}
        >
          <FeatureIconContainer>
            <BedOutlinedIcon fontSize="large" />
          </FeatureIconContainer>
          <Typography fontWeight={700}>
            {t('featureDemo.homeStaging')}
          </Typography>
        </FeatureDemoCard>
        <FeatureDemoCard
          target="_blank"
          onClick={() => {
            openDialog({
              variant: 'demo',
              title: t('demoDialog.skyReplacement.title'),
              message: t('demoDialog.skyReplacement.description'),
              ctaButtonLabel: t('demoDialog.skyReplacement.ctaLabel'),
              onConfirm: () => {
                window.open(externalLinks.skyReplacementDemoUrl, '_blank');
                trackButtonClicked('Sky Replacement Demo Clicked', {
                  page: 'Home',
                });
              },
              onClose: () => dismissDialog(),
              headerImgSlot: (
                <TwicVideo
                  src={assets.cdnVideoAssets.skyReplacementDialogHeaderVideo}
                  ratio="1.9"
                  alt="Sky Replacement demo video"
                />
              ),
            });
          }}
        >
          <FeatureIconContainer>
            <CloudOutlinedIcon fontSize="large" />
          </FeatureIconContainer>
          <Typography fontWeight={700} sx={{ textWrap: 'balance' }}>
            {t('featureDemo.skyReplacement')}
          </Typography>
        </FeatureDemoCard>
        <FeatureDemoCard
          target="_blank"
          onClick={() => {
            openDialog({
              variant: 'demo',
              title: t('demoDialog.wallPainting.title'),
              message: t('demoDialog.wallPainting.description'),
              ctaButtonLabel: t('demoDialog.wallPainting.ctaLabel'),
              onConfirm: () => {
                dismissDialog();
                trackButtonClicked('Wall Painting Demo Clicked', {
                  page: 'Home',
                });
              },
              onClose: () => dismissDialog(),
              headerImgSlot: (
                <TwicVideo
                  src={assets.cdnVideoAssets.wallPaintingDialogHeaderVideo}
                  ratio="1.9"
                  alt="Wall painting demo video"
                />
              ),
            });
          }}
        >
          <StyledBadge>New</StyledBadge>
          <FeatureIconContainer>
            <ImagesearchRollerOutlinedIcon fontSize="large" />
          </FeatureIconContainer>
          <Typography fontWeight={700} sx={{ textWrap: 'balance' }}>
            {t('featureDemo.wallPainting')}
          </Typography>
        </FeatureDemoCard>
      </FeatureDemoContainer>
    </Box>
  );
};
