import styled from '@/utils/styled';
import { breakpoints, colors, fonts, fontSize, getMeeSpacing } from '@/theme';
import { GLOBAL_PADDING } from '@/components/templates/Navigation/Navigation.styles';
import { OpenInNewIcon, Typography } from '@/components/common';

export const HomepageContainer = styled('div')`
  display: flex;
  flex-direction: column;
  row-gap: ${getMeeSpacing(5)};
  padding: ${getMeeSpacing(4, GLOBAL_PADDING)};

  @media screen and (max-width: ${breakpoints.sm}px) {
    [data-element='header'] {
      display: none;
    }
  }
`;

export const HomeGridLayout = styled('div')`
  display: grid;
  grid-auto-flow: row dense;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-gap: ${getMeeSpacing(4, 2.5)};

  .album-card-container:nth-of-type(4),
  .skeleton-container:nth-of-type(4) {
    display: none;

    @media (min-width: ${breakpoints.xl}px) {
      display: flex;
    }
  }

  @media (min-width: ${breakpoints.xs}px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (min-width: ${breakpoints.sm}px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media (min-width: ${breakpoints.lg}px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media (min-width: ${breakpoints.xl}px) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
`;

export const EmptyStateContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  gap: ${getMeeSpacing(3)};
  [data-testid='empty list'] {
    min-height: unset;
  }

  @media (min-width: ${breakpoints.xl}px) {
    padding: ${getMeeSpacing(4)};
  }
`;

export const StyledBadge = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  margin: ${getMeeSpacing(1.25)};
  padding: ${getMeeSpacing(0.2, 0.5)};
  border-radius: 5px;
  background-color: ${colors.secondary};
  color: ${colors.white};
  font-size: ${fontSize.xSmall};
  animation: badge-animation 800ms infinite alternate;
  @keyframes badge-animation {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.1);
    }
  }
`;

export const StyledOpenInNewIcon = styled(OpenInNewIcon)`
  position: absolute;
  top: 0;
  right: 0;
  margin: ${getMeeSpacing(2)};
  color: ${colors.miracle50};
  transition: all 0.1s ease-in-out;
`;

export const FeatureIconContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 8px;
  background-color: ${colors.moonWhite};
`;

export const FeatureDemoContainer = styled('div')`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-wrap: wrap;
  gap: ${getMeeSpacing(2)};
  cursor: pointer;

  @media (min-width: ${breakpoints.md}px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const FeatureDemoCard = styled('a')`
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 1;
  gap: ${getMeeSpacing(2)};
  padding: ${getMeeSpacing(2)};
  border: 1px solid ${colors.miracle20};
  border-radius: 12px;
  transition: all 0.1s ease-in-out;

  :hover {
    border-color: ${colors.miracle50};
    ${StyledOpenInNewIcon} {
      color: unset;
      margin: ${getMeeSpacing(1.5)};
    }
  }

  ${FeatureIconContainer} {
    background-color: ${colors.moonWhite};
  }
`;

export const CreatePropertyCardContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const HiddenContent = styled('div')`
  width: 100%;
  height: 48px;
`;

export const AddIconContainer = styled('div')`
  position: absolute;
  background-color: ${colors.whiteSmoke};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 12px;
`;

export const PageTitle = styled(Typography)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${getMeeSpacing(0.5)};
  color: ${colors.infinity};
  font-family: ${fonts.secondary};
  font-size: 28px;

  @media screen and (min-width: ${breakpoints.sm}px) {
    font-size: 34px;
    gap: ${getMeeSpacing(2)};
  }
`;
