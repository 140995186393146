import { useTranslation } from 'next-i18next';
import { TwicImg } from '@twicpics/components/react';
import { Box, Typography } from '@/components/common';
import { AppDownloadLinks } from '@/components/common/AppQRCodeAndLinks/AppQRCodeAndLinks';
import { assets } from '@/config/assets-links';
import {
  AppQrCodeWrapper,
  CirclePhoneContainer,
  PhonesImageWrapper,
  PhotoshootContent,
  StartPhotoshootContainer,
} from './AppDownloadInstructions.styles';

const PHONE_IMAGE_RATIO = 275 / 292;
export function AppDownloadInstructions() {
  const { t } = useTranslation('home');

  return (
    <StartPhotoshootContainer>
      <AppQrCodeWrapper />
      <PhotoshootContent>
        <Box display="flex" flexDirection="column" rowGap={0.5} zIndex={1}>
          <Typography variant="h5" color="primary">
            {t('emptyState.startApp.title')}
          </Typography>
          <Typography color="primary" maxWidth="55ch">
            {t('emptyState.startApp.explanation')}
          </Typography>
        </Box>
        <Box display="flex" gap={2}>
          <AppDownloadLinks />
        </Box>
      </PhotoshootContent>
      <CirclePhoneContainer aria-label="circlePhone" />
      <PhonesImageWrapper aria-label="PhonesImgContainer">
        <TwicImg
          src={assets.cdnImageAssets.properShotPhone}
          mode="cover"
          preTransform="quality=100"
          ratio={PHONE_IMAGE_RATIO}
        />
      </PhonesImageWrapper>
    </StartPhotoshootContainer>
  );
}
