import { AppQRCode } from '@/components/common/AppQRCodeAndLinks/AppQRCodeAndLinks';
import { breakpoints, colors, getMeeSpacing } from '@/theme';
import styled from '@/utils/styled';

export const PhotoshootContent = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: ${getMeeSpacing(2)};
  width: 100%;
`;

export const PhonesImageWrapper = styled('div')`
  height: 150px;
  aspect-ratio: 275 / 292;
`;

export const CirclePhoneContainer = styled('div')`
  position: absolute;
  right: -50px;
  width: 300px;
  height: 300px;
  border-radius: 10%;
  transform: rotate(-45deg);
  background-color: ${colors.white};
  opacity: 0.5;
`;

export const AppQrCodeWrapper = styled(AppQRCode)`
  background-color: ${colors.moonWhite};

  @media screen and (max-width: ${breakpoints.md}px) {
    display: none;
  }
`;

export const StartPhotoshootContainer = styled('div')`
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: ${colors.moonWhite};
  padding: ${getMeeSpacing(3)};
  border-radius: 12px;
  gap: ${getMeeSpacing(3)};
  overflow: hidden;

  @media screen and (max-width: ${breakpoints.md}px) {
    align-items: unset;
    flex-direction: column;
    row-gap: ${getMeeSpacing(3)};
    height: unset;

    ${PhonesImageWrapper},
    ${CirclePhoneContainer} {
      display: none;
    }
  }
`;
